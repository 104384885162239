/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
$(function () {
  const $carousel = $('.js-carousel');
  $carousel.slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  });

  $('.js-carousel__prev').on('click', function () {
    $carousel.slick('slickPrev');
  });

  $('.js-carousel__next').on('click', function () {
    $carousel.slick('slickNext');
  });
});
